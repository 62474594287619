<template>
		<div>
		<b-row>
			<b-colxx xxs="12">
		      	<b-card :title="$t('Editar perfil')">
		      		
				    <b-colxx xxs="12">
				        
			          <b-form @submit.prevent="onGridFormSubmit">
			            <b-row>
			            	<!--<b-colxx sm="12">
                    
		                    	<b-input-group class="mb-3">
		                      	<img src="/static/img/card-thumb-1.jpg">
		                      	<b-form-file v-model="gridForm.file"  :placeholder="$t('input-groups.choose-file')"></b-form-file>
		                    </b-input-group>
		                  </b-colxx>-->
			              <b-colxx sm="6">
			                <b-form-group :label="$t('forms.email')">
			                  <b-form-input type="email" v-model="gridForm.email" />
			                </b-form-group>
			              </b-colxx>
			              <b-colxx sm="6">
			                <b-form-group :label="$t('forms.password')">
			                  <b-form-input type="password" v-model="gridForm.password"/>
			                </b-form-group>
			              </b-colxx>

			              <b-colxx sm="12">
			                <b-form-group :label="$t('forms.address')">
			                  <b-form-input v-model="gridForm.address1" ></b-form-input>
			                </b-form-group>
			              </b-colxx>

			              <b-colxx sm="12">
			                <b-form-group :label="$t('forms.address2')">
			                  <b-form-input v-model="gridForm.address2" ></b-form-input>
			                </b-form-group>
			              </b-colxx>

			              <b-colxx sm="6">
			                <b-form-group :label="$t('forms.city')">
			                  <b-form-input v-model="gridForm.city" ></b-form-input>
			                </b-form-group>
			              </b-colxx>
			              <b-colxx sm="4">
			                <b-form-group :label="$t('forms.state')">
			                  <b-form-select v-model="gridForm.state" :options="stateOptions" plain  />
			                </b-form-group>
			              </b-colxx>
			              <b-colxx sm="2">
			                <b-form-group :label="$t('forms.zip')">
			                  <b-form-input v-model="gridForm.zip" ></b-form-input>
			                </b-form-group>
			              </b-colxx>
			            </b-row>

			            <!--<b-button type="button" variant="primary" class="mt-4">{{ $t('forms.signin') }}</b-button>-->

			            <b-button type="button" variant="primary" class="mt-4">Guardar cambios</b-button>


			          </b-form>
				    </b-colxx>

		      	</b-card>
			</b-colxx>
		</b-row>
	</div>
</template>
<script>
	export default{
		data(){
			return{
				gridForm: {
			        email: '',
			        password: '',
			        address1: '',
			        address2: '',
			        city: '',
			        state: '',
			        zip: ''
			      }
			}
		},

		mounted: function(){

		},

		methods: {
			getUserInfo(){
				//Datos de usuario
				//Datos de suscripcion actual
				
			}
		}
	}
</script>